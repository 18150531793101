let total;
var glob = {}; // Globally scoped object

function createSum() {
	// Generate captcha numbers
	function getRandom() {
		return Math.ceil(Math.random() * 10);
	}
	let randomNum1 = getRandom(),
		randomNum2 = getRandom();
	glob.total = randomNum1 + randomNum2;
	$('#question').text(randomNum1 + ' + ' + randomNum2 + ' =');
	console.log(glob.total);
}

createSum();

// On "reset button" click, generate new random sum
// $('button[type=reset]').click(createSum);
$('button[type=reset]').click(function () {
	createSum();
	document
		.querySelector('#captcha')
		.setAttribute('data-validation-captcha', glob.total);
});

// Config
var config = {
	lang: 'en',
	// validate form id
	form: 'form',
	// input validate settings
	validate: {
		'#name': {
			validation: 'required',
			// help: 'lorem',
		},
		'#family-name': {
			validation: 'required',
			// help: 'lorem',
		},

		'#country': {
			validation: 'required',
			// help: 'lorem',
		},

		'#company-type': {
			validation: 'required',

		},

		
		'#product-category': {
			validation: 'required',
			// help: 'lorem',
		},

		'#organization-title': {
			validation: 'required',
			help: 'If none company represented, please type in “Professional individual"',
		},

		// '.chosen-search-input': {
		// 	validation: 'required',
		// 	// help: 'lorem',
		// },

		'#mrs': {
			validation: 'required',
			// help: 'lorem',
		},
		'#mr': {
			validation: 'required',
			// help: 'lorem',
		},

		'#participate': {
			validation: 'required',
			// help: 'lorem',
		},
		'#fullname': {
			validation: 'required',
			// help: 'lorem',
		},
		'#email': {
			validation: 'required email',
		},
		'#textarea': {
			validation: 'required',
		},
		'#checkbox': {
			validation: 'required',
			// overwrite message: 'error-msg-{validation modules}: ""'
			'error-msg-required': 'Please accept our terms',
		},
		'#privacy-policy': {
			validation: 'required',
			// overwrite message: 'error-msg-{validation modules}: ""'
			'error-msg-required': 'Please accept our terms',
		},
		'#visitor-terms': {
			validation: 'required',
			// overwrite message: 'error-msg-{validation modules}: ""'
			'error-msg-required': 'Please accept our terms',
		},
		'#captcha': {
			validation: 'required number spamcheck',
			captcha: glob.total,
			'error-msg-spamcheck': 'Wrong equation',
		},
	},
};

$.validate({
	modules: 'jsconf, security, toggleDisabled',
	disabledFormFilter: 'form',
	onModulesLoaded: function () {
		$.setupValidation(config);
	},
});

$('#textarea').restrictLength($('#max-length-element'));
