/* Vendor files ------------------------------------ */

// import svg4everybody from './vendor/svg4everybody.js';
import hideOnScroll from './partial/hide-on-scroll.js';
import anchorHighlight from './partial/anchor-highlight-section.js';
import aos from './partial/aos.js';
import formValidate from './partial/form-validate.js';
import hamburgerMenu from './partial/hamburger-menu';

// Move header top links on mobile
$(document).ready(function () {
	function movePaste() {
		var currentWidth = window.innerWidth;
		if (currentWidth < 900) {
			$('.js-main-nav').append($('.add-calendar'));
		} else {
			$('.logo-wrap').append($('.add-calendar'));
		}
	}
	movePaste();
	$(window).resize(movePaste);

	function varCreate() {
		var headerMobile = document.querySelector('.header__wrap');
		var headerHeight = headerMobile.clientHeight;

		var header = document.querySelector('.mainHeader');
		var height = header.clientHeight;

		document.documentElement.setAttribute(
			'style',
			`--header-height-mobile: ${headerHeight}px;
		--header-height: ${height}px`
		);
	}
	varCreate();
	$(window).resize(varCreate);
});

$('.link__select').hover(function () {
	var isHovered = $(this).is(':hover');
	if (isHovered) {
		$(this).children('ul').toggleClass('is-active');
	} else {
		$(this).children('ul').toggleClass('is-active');
	}
});

// Accordion
$('.accordion-trigger').click(function () {
	$(this).toggleClass('active');
	$(this).next().slideToggle(200).parent().toggleClass('is-active');
});

// Placeholder delete on focus
// $(function () {
// 	$('input,textarea')
// 		.focus(function () {
// 			$(this)
// 				.data('placeholder', $(this).attr('placeholder'))
// 				.attr('placeholder', '');
// 		})
// 		.blur(function () {
// 			$(this).attr('placeholder', $(this).data('placeholder'));
// 		});
// });

$('h1, h2, h3, h4, h5, p').each(function () {
	var tekst = $(this).html();
	tekst = tekst.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;'); //jednoznakowe
	tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, '$1$2&nbsp;'); //dwuznakowe
	$(this).html(tekst);
});

var max = 200;
var tot, str;
$('.js-text-ellipsis').each(function () {
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

$('.js-text-ellipsis-140').each(function () {
	var max = 140;
	var tot, str;
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

tabControl();

/*
We also apply the switch when a viewport change is detected on the fly
(e.g. when you resize the browser window or flip your device from 
portrait mode to landscape). We set a timer with a small delay to run 
it only once when the resizing ends. It's not perfect, but it's better
than have it running constantly during the action of resizing.
*/
var resizeTimer;
$(window).on('resize', function (e) {
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function () {
		tabControl();
	}, 250);
});

/*
The function below is responsible for switching the tabs when clicked.
It switches both the tabs and the accordion buttons even if 
only the one or the other can be visible on a screen. We prefer
that in order to have a consistent selection in case the viewport
changes (e.g. when you esize the browser window or flip your 
device from portrait mode to landscape).
*/
function tabControl() {
	var tabs = $('.tabbed-content').find('.tabs');
	if (tabs.is(':visible')) {
		tabs.find('a').on('click', function (event) {
			event.preventDefault();
			var target = $(this).attr('href'),
				tabs = $(this).parents('.tabs'),
				buttons = tabs.find('a'),
				item = tabs.parents('.tabbed-content').find('.item');
			buttons.removeClass('active');
			item.removeClass('active');
			$(this).addClass('active');
			$(target).addClass('active');
		});
	} else {
		$('.item').on('click', function () {
			var container = $(this).parents('.tabbed-content'),
				currId = $(this).attr('id'),
				items = container.find('.item');
			container.find('.tabs a').removeClass('active');
			items.removeClass('active');
			$(this).addClass('active');
			container.find('.tabs a[href$="#' + currId + '"]').addClass('active');
		});
	}
}

// Counter
const stats = document.querySelectorAll('.counter');

stats.forEach((stat) => {
	// pattern used to seperate input number from html into an array of numbers and non numbers. EX $65.3M -> ["$65.3M", "$", "65", ".", "3", "M"]
	const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?(\D+)?(\d+)(\D+)?(\D+)?(\d+)?(\D+)?/;
	const time = 1000;
	let result = [...patt.exec(stat.textContent)];
	let fresh = true;
	let ticks;

	// Remove first full match from result array (we dont need the full match, just the individual match groups).
	result.shift();
	// Remove undefined values from result array where they didnt have a match in one of the optional regex groups
	result = result.filter((res) => res != null);

	while (stat.firstChild) {
		stat.removeChild(stat.firstChild);
	}

	for (let res of result) {
		if (isNaN(res)) {
			stat.insertAdjacentHTML('beforeend', `<span>${res}</span>`);
		} else {
			for (let i = 0; i < res.length; i++) {
				stat.insertAdjacentHTML(
					'beforeend',
					`<span data-value="${res[i]}">
						<span>&ndash;</span>
						${Array(parseInt(res[i]) + 1)
							.join(0)
							.split(0)
							.map(
								(x, j) => `
							<span>${j}</span>
						`
							)
							.join('')}
					</span>`
				);
			}
		}
	}

	ticks = [...stat.querySelectorAll('span[data-value]')];

	let activate = () => {
		let top = stat.getBoundingClientRect().top;
		let offset = (window.innerHeight * 3) / 4;

		setTimeout(() => {
			fresh = false;
		}, time);

		if (top < offset) {
			setTimeout(
				() => {
					for (let tick of ticks) {
						let dist = parseInt(tick.getAttribute('data-value')) + 1;
						tick.style.transform = `translateY(-${dist * 100}%)`;
					}
				},
				fresh ? time : 0
			);
			window.removeEventListener('scroll', activate);
		}
	};
	window.addEventListener('scroll', activate);
	activate();
});

$(document).ready(function () {
	$('#acc-active').click();
});

// $(function () {
// 	$('.woocommerce-account-fields input')
// 		.change(function () {
// 			$('div.create-account').toggle(this.checked);
// 		})
// 		.change(); //ensure visible state matches initially
// });

// menu horizontal scrolling
$('#sticky-js a').on('click', function () {
	$('#sticky-js a').removeClass('selected');
	$(this).addClass('selected');
	// CALL scrollCenter PLUSGIN
	$('#sticky-js').scrollCenter('.selected', 200);
});

jQuery.fn.scrollCenter = function (elem, speed) {
	var active = jQuery(this).find(elem); // find the active element
	var activeWidth = active.width() / 2; // get active width center
	var pos = active.position().left + activeWidth; //get left position of active li + center position
	var elpos = jQuery(this).scrollLeft(); // get current scroll position
	var elW = jQuery(this).width(); //get div width
	//var divwidth = jQuery(elem).width(); //get div width
	pos = pos + elpos - elW / 2 + 16 + 'px'; // for center position if you want adjust then change this

	jQuery(this).animate(
		{
			scrollLeft: pos,
		},
		speed == undefined ? 1000 : speed
	);
	return this;
};

$('#sticky-js a.selected').trigger('click');

// $.fn.parallax = function(resistance, mouse) {
// 	$el = $(this);
// 	TweenLite.to($el, 0.5, {
// 		x: -((mouse.clientX - window.innerWidth / 2) / resistance),
// 		y: -((mouse.clientY - window.innerHeight / 8) / resistance)
// 	});
// };

// $(document).mousemove(function(e) {
// 	$('.img-parralax').parallax(-50, e);
// 	$('.cloud1').parallax(150, e);
// });

jQuery(function () {
	function setAspectRatio() {
		jQuery('iframe').each(function () {
			jQuery(this).css('height', (jQuery(this).width() * 9) / 16);
		});
	}

	setAspectRatio();
	jQuery(window).resize(setAspectRatio);
});
import chosen from 'chosen-js';
$('.chosen-select-1').chosen({
	placeholder_text_multiple: '-- select Company type',
});

$('.chosen-select-2').chosen({
	placeholder_text_multiple: '-- select Product category',
});

$('.chosen-select-3').chosen({
	placeholder_text_multiple: '-- select Title',
});

import intlTelInput from 'intl-tel-input';
import utils from './partial/intl/utils';

const input = document.querySelector('#phone');
intlTelInput(input, {
	initialCountry: 'auto',
	nationalMode: false,
	geoIpLookup: function (success, failure) {
		$.get('https://ipinfo.io', function () {}, 'jsonp').always(function (resp) {
			var countryCode = resp && resp.country ? resp.country : 'pl';
			success(countryCode);
		});
	},
});
